/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = function() {
  // Serve build to test Heap on localhost
  // Comment to use app locally
  // Load Heap
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};     
  
  try {
    const HEAP_APP_ID =
      window && window.location && window.location.hostname === 'unroll.me'
        ? '3543597151'
        : '821966953'

    window.heap.load(HEAP_APP_ID, {
      disableTextCapture: false,
      secureCookie: true
    })
  } catch (err) {
    console.error('Heap initialization error', err)
  }
}
