// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jojo-hedaya-js": () => import("./../../../src/pages/jojo-hedaya.js" /* webpackChunkName: "component---src-pages-jojo-hedaya-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-terms-js": () => import("./../../../src/pages/legal/terms.js" /* webpackChunkName: "component---src-pages-legal-terms-js" */),
  "component---src-pages-promos-promo-29-05-js": () => import("./../../../src/pages/promos/promo-29-05.js" /* webpackChunkName: "component---src-pages-promos-promo-29-05-js" */),
  "component---src-pages-your-data-js": () => import("./../../../src/pages/your-data.js" /* webpackChunkName: "component---src-pages-your-data-js" */)
}

